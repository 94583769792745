<template>
  <div class="content">
    <PageHeader :title="$tc('app.user', 2)" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col">
          <users-card :data="users" />
        </div>
        <div v-if="$store.state.plans.omniPlan" class="col">
          <departments-card :data="users" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import UsersCard from '@/components/cards/UsersCard.vue';
import DepartmentsCard from '@/components/cards/DepartmentsCard.vue';

export default {
  name: 'Users',
  components: {
    PageHeader,
    UsersCard,
    DepartmentsCard,
  },
  data() {
    return {
      users: [],
      fetched: false,
      // search: '',
      form: {
        q: '',
        page: 1,
      },
      pages: 1,
    };
  },
  computed: {
    agentStatus() {
      const agents = {};

      if (this.$live.managedAgents?.length) {
        this.$live.managedAgents.forEach((agent) => {
          if (agent.email) agents[agent.email] = agent.status;
        });
      }

      return agents;
    },
  },
  methods: {},
};
</script>
<style scoped lang="scss">
</style>
