<template>
  <card
    ref="card"
    actions
    block
    :title="$tc('app.department', 2)"
    :loading="loading"
    @reload="fetch"
  >
    <template #body>
      <data-table
        :data="departments"
        :pages="pages"
        :columns="[
          'name',
          'description',
          {
            key: 'active',
            label: 'status',
          },
        ]"
        searchable
        @search="search"
        @change-page="fetch"
      >
        <template #buttons>
          <a
            v-modal="{ target: 'department-modal' }"
            href="javascript:void(0)"
            class="btn btn-primary"
            aria-expanded="false"
          >
            <i
              class="
                fas
                fa-plus-circle
                text-white
                font-size-22
                v-align-text-bottom
              "
            ></i>
          </a>
        </template>
        <template #actions="item">
          <dropdown>
            <template v-slot:text>
              <i class="fas fa-ellipsis-v"></i>
            </template>
            <template #items>
              <a
                href="javascript:void(0)"
                class="dropdown-item"
                v-modal="{ target: 'department-modal', data: item }"
              >
                {{ $t('generic-str.edit') }}
              </a>
            </template>
          </dropdown>
        </template>
      </data-table>
      <department-modal
        id="department-modal"
        @created="fetch"
        @updated="fetch"
      ></department-modal>
    </template>
  </card>
</template>

<script>
// @ is an alias to /src
import Card from '@/components/Card.vue';
import DataTable from '@/components/DataTable.vue';
import Dropdown from '@/components/Dropdown.vue';
import DepartmentModal from '@/components/departments/DepartmentModal.vue';

export default {
  name: 'DepartmentsCard',
  components: {
    Card,
    DataTable,
    Dropdown,
    DepartmentModal,
  },
  data() {
    return {
      loading: true,
      q: '',
      page: 1,
      pages: 1,
      departments: [],
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(page = this.page) {
      this.page = page;
      this.loading = true;

      this.$store.dispatch('fetchDepartments', { page, q: this.q }).then((response) => {
        this.departments = response.data;
        this.pages = response.last_page;
        this.loading = false;
        this.$emit('departments', this.departments);
      });
    },
    search(q) {
      this.q = q;
      this.fetch(1);
    },
  },
};
</script>
