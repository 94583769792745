<template>
  <card
    ref="card"
    actions
    search
    block
    :title="$tc('app.user', 2)"
    :loading="loading"
    @reload="fetch"
  >
    <template #body>
      <data-table
        :data="users"
        :pages="pages"
        :columns="[
          'name',
          'email',
          {
            key: 'pivot.role',
            label: 'role',
            localize: true,
          },
          {
            key: 'departments.name',
            label: 'department',
            hide: !$store.state.plans.omniPlan,
          },
          {
            key: 'pivot.is_agent',
            label: 'agent',
            hide: !$store.state.plans.omniPlan,
          },
        ]"
        searchable
        @search="search"
        @change-page="fetch"
      >
        <template #buttons>
          <router-link
            class="btn btn-primary m-b-5"
            :to="{ name: 'InviteUser' }"
          >
            <i
              class="
                fas
                fa-plus-circle
                text-white
                font-size-22
                v-align-text-bottom
              "
            ></i>
          </router-link>
        </template>
        <template #actions="item">
          <dropdown>
            <template v-slot:text>
              <i class="fas fa-ellipsis-v"></i>
            </template>
            <template #items>
              <router-link
                :to="{ name: 'UsersEdit', params: { id: item.id } }"
                class="dropdown-item"
              >
                {{ $t('generic-str.edit') }}
              </router-link>
              <button class="dropdown-item" @click="destroy(item.id)">
                {{ $t('generic-str.remove') }}
              </button>
            </template>
          </dropdown>
        </template>
      </data-table>
    </template>
  </card>
</template>

<script>
// @ is an alias to /src
import Swal from 'sweetalert2';
import Card from '@/components/Card.vue';
import DataTable from '@/components/DataTable.vue';
import Dropdown from '@/components/Dropdown.vue';

export default {
  name: 'UsersCard',
  components: {
    Card,
    DataTable,
    Dropdown,
  },
  data() {
    return {
      loading: true,
      q: '',
      page: 1,
      pages: 1,
      users: [],
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(page = this.page) {
      this.page = page;
      this.loading = true;
      this.$store
        .dispatch('fetchUsers', { page, q: this.q })
        .then((response) => {
          this.users = response.data;
          this.pages = response.last_page;
          this.loading = false;
        });
    },
    search(q) {
      this.q = q;
      this.fetch(1);
    },
    destroy(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: this.$t('user.destroy'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          this.$store.dispatch('destroyUser', id).then((response) => {
            this.fetch(1);
          });
        }
      });
    },
  },
};
</script>
