var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{ref:"card",attrs:{"actions":"","search":"","block":"","title":_vm.$tc('app.user', 2),"loading":_vm.loading},on:{"reload":_vm.fetch},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('data-table',{attrs:{"data":_vm.users,"pages":_vm.pages,"columns":[
        'name',
        'email',
        {
          key: 'pivot.role',
          label: 'role',
          localize: true,
        },
        {
          key: 'departments.name',
          label: 'department',
          hide: !_vm.$store.state.plans.omniPlan,
        },
        {
          key: 'pivot.is_agent',
          label: 'agent',
          hide: !_vm.$store.state.plans.omniPlan,
        } ],"searchable":""},on:{"search":_vm.search,"change-page":_vm.fetch},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('router-link',{staticClass:"btn btn-primary m-b-5",attrs:{"to":{ name: 'InviteUser' }}},[_c('i',{staticClass:"\n              fas\n              fa-plus-circle\n              text-white\n              font-size-22\n              v-align-text-bottom\n            "})])]},proxy:true},{key:"actions",fn:function(item){return [_c('dropdown',{scopedSlots:_vm._u([{key:"text",fn:function(){return [_c('i',{staticClass:"fas fa-ellipsis-v"})]},proxy:true},{key:"items",fn:function(){return [_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{ name: 'UsersEdit', params: { id: item.id } }}},[_vm._v(" "+_vm._s(_vm.$t('generic-str.edit'))+" ")]),_c('button',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.destroy(item.id)}}},[_vm._v(" "+_vm._s(_vm.$t('generic-str.remove'))+" ")])]},proxy:true}],null,true)})]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }